import React from "react"
import {Link} from "gatsby"

import Hero from "../components/hero" 
import Layout from "../components/layout"
import MaxWidth from "../components/maxWidth.js"
import SEO from "../components/seo"

const ContactPage = () => (
	 <>
	  <Hero>
	  	<h1>Your Privacy</h1>
	  	<p>All contact that you have with the EAP is confidential.</p>
	  </Hero>
	  <Layout>
	  	<SEO title="Your Privacy | Employee Assistance Help &amp; Resources" keywords={[`Employee Assistance`, `EAP Privacy`, `HIPAA`, `Employee Privacy`, `Employee Help in Boston`]} description="All contact with an EAP clinician is confidential."/>
		<MaxWidth>
			<p>No names or concerns are disclosed to your employer. No information is retained in your personnel or medical files. Information is released only at your written consent.</p>

			<p>Your use of the EAP will neither protect nor adversely affect your employment at your company. For more information on how Corporate EAP Resources maintains confidentiality, view our <a href='/hipaa-privacy-of-corporate-eap-resources.pdf?refresh' target='_blank' rel="noopener noreferrer">privacy policy</a>.</p>

			<p>For information regarding your medical privacy with respect to national standards, visit:<br/>
			<a href='https://www.hhs.gov/hipaa/for-professionals/privacy/laws-regulations/index.html' target='_blank' rel="noopener noreferrer">U.S. Department of Health &amp; Human Services</a></p>

			<p>If you have any questions regarding privacy, feel free to reach out to us at <a href='mailto:info@eap.help'>info@eap.help</a> or <Link to='/contact-us/'>contact us here</Link>.</p>
		</MaxWidth>
	  </Layout>
	 </>
)

export default ContactPage